import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { LockClosedIcon, PlusIcon } from "@heroicons/react/solid";

import LinkedInvoice from "./LinkedInvoice";
import LineItem from "./LineItem";
import NumberDisplay from "./fields/NumberDisplay";
import TaxSelect from "./fields/TaxSelect";
import InvoiceActions from "./menus/InvoiceActions";
import { DateTime } from "luxon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class RepeatingInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  copyToClipboard(data) {
    // Using deprecated document.execCommand because of permissions on parent iframe
    var textField = document.createElement("textarea");
    textField.innerText = this.props.invoice.external_url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  }

  changeToNonRecurring() {
    this.props.updateSetting("mode", "Custom");
  }

  addLineItem = (e) => {
    e.preventDefault();

    const { country, connectionType } = this.props.connectionData;
    const isQboUs = country === "US" && connectionType === "qbo";

    const data = {
      tax: this.props.invoice.customTaxes ? 0 : this.props.deal.settings?.tax || 0,
      taxType: isQboUs ? "NON" : this.props.invoice.customTaxes ? false : this.props.deal.settings?.taxType || false,
      account: this.props.invoice.customTaxes ? "NONE" : this.props.deal.settings?.account ||"NONE"
    }
    
    this.props.addItem(this.props.invoiceIndex, data);
  }

  getOrdinalNum (number) {
    let selector;

    if (number <= 0) {
      selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
      selector = 0;
    } else {
      selector = number % 10;
    }

    return ['th', 'st', 'nd', 'rd', ''][selector];
  };

  getAllLinkedInvoices() {
    let invoices = this.props.invoice?.linked_invoices || [];
    let invoice = this.props.invoice;

    while (invoice?.previous_invoice) {
      if (invoice.previous_invoice?.linked_invoices) {
        invoices = [...invoices, ...invoice.previous_invoice.linked_invoices];
      }

      invoice = invoice.previous_invoice;
    }

    return invoices;
  }

  getPreviousInvoice(invoice) {
    if (!invoice.previous_invoice) {
      return null;
    }
    console.log(DateTime.fromISO(invoice.created));
    return (
      <>
        <tbody>
          <tr>
            <td className="py-2 pl-4 pr-3 text-xs font-medium text-gray-900 whitespace-nowrap sm:pl-6 md:pl-0">
              Repeating Invoice Updated
            </td>
            <td className="px-3 py-2 text-xs text-gray-500 whitespace-nowrap">
              {DateTime.fromISO(invoice.created).toLocaleString(DateTime.DATETIME_SHORT)}
            </td>
          </tr>
        </tbody>

        {invoice.previous_invoice?.linked_invoices.length ? (
          <tbody className="bg-white divide-y divide-gray-200">
            {invoice.previous_invoice.linked_invoices.map((inv, index) => (
              <LinkedInvoice invoice={inv} key={index} shortCode={this.props.shortCode} />
            ))}
          </tbody>
        ) : null}

        {this.getPreviousInvoice(invoice.previous_invoice)}
      </>
    );
  }

  hasEditableTax = () => {
    const invoice = this.props.invoice;

    if (["AUTHORISED", "PAID", "SENT", "VOIDED", "DELETED"].includes(invoice.status)) {
      return false;
    }

    return invoice.line_items.some(item => item.taxType === 'TAX');
  }

  totalIncTaxComponent = () => {
    const invoice = this.props.invoice;
    const taxNeedsCalculating = this.hasEditableTax() && invoice.taxType === 'TAX' && invoice.totalTax === 0;

    if (taxNeedsCalculating) {
      return (
        <label className="flex items-center gap-5 text-right">
          <span className="text-xs text-grey">Total inc. Tax</span>
          <span>Automated</span>
        </label>
      );
    }

    if (invoice.totalIncTax !== invoice.total) {
      return (
        <label className="flex items-center gap-5 text-right">
          <span className="text-xs text-grey">Total inc. Tax</span>
          <NumberDisplay
            className="w-24"
            value={this.props.invoice.totalIncTax}
            currency={this.props.invoice.currency}
          />
        </label>
      );
    }
  }

  getViewLink = () => {
    const invoice = this.props.invoice;
    const connectionType = this.props.connectionData?.connectionType;

    if (connectionType === "xero" && invoice?.external_id) {
      return `https://go.xero.com/RepeatTransactions/Edit.aspx?invoiceID=${invoice.external_id}&type=AR`
    }

    if (connectionType === "qbo" && invoice?.external_id) {
      return `https://app.sandbox.qbo.intuit.com/app/invoice?templateAction=GET&txnId=${invoice.external_id}`;
    }

    return false;
  }

  getTaxList = (isQboUs) => {
    const taxes = this.props?.taxes;

    if (!taxes) {
      return [];
    }

    let taxList = taxes.map((x) => {
      return { value: x.type, label: x.name };
    });

    if (isQboUs) {
      taxList = [...[{ value: "TAX", label: "Automated" }], ...taxList];
    } else {
      taxList = [...[{ value: false, label: "Not Set" }], ...taxList];
    }

    return taxList;
  }

  updateTax = (value) => {
    // This tax type is for US connections only and means QBO will
    // Auto-calculate based on location.
    if (value === 'TAX') {
      this.props.updateInvoice(this.props.invoiceIndex, "taxType", value);
      this.props.updateInvoice(this.props.invoiceIndex, "totalTax", 0);
    } else {
      const tax = this.props.taxes.find((x) => x.type === value);
      this.props.updateInvoice(this.props.invoiceIndex, "taxType", tax.type);
    }
  }

  render() {
    const { connectionType, country } = this.props?.connectionData;
    const isQboUs = connectionType === "qbo" && country === "US";

    const taxList = this.getTaxList(isQboUs);

    const allLinkedInvoices = this.getAllLinkedInvoices();
    const allLinkedInvoicesCount = allLinkedInvoices.length;

    const allLinkedInvoicesTotal = allLinkedInvoices.reduce(function (sum, i) {
      const total = Number(i.total);
      const creditExcTax = i?.creditExcTax ? Number(i.creditExcTax) : 0;

      return i.status !== "VOIDED" && i.status !== "DELETED"
        ? sum + total - creditExcTax
        : sum;
    }, 0);

    const locked = this.props.invoice?.external_id && connectionType === "xero" || this.props.invoice.status === 'DELETED';

    const cancelEnabled = !this.props.preferences?.financeMode && this.props.invoice?.external_id && this.props.invoice?.status !== 'DELETED';
    const replaceEnabled = this.props.invoice?.external_id && this.props.invoice?.status !== 'DELETED' && connectionType === 'xero' && !this.props.preferences?.disableEditXeroRecurring;

    const lineItemNode = this.props.invoice.line_items.map((item, itemIndex) => {
      return (
        <Draggable key={item.key} draggableId={item.key.toString()} index={itemIndex}>
          {(provided, snapshot) => {
            const style = {
              borderTopColor: snapshot.isDragging ? "#e5e7eb " : "",
              borderTopWidth: snapshot.isDragging ? "1px" : "",
              ...provided.draggableProps.style,
            };
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={style}
              >
                <LineItem
                  cloneItem={this.props.cloneItem}
                  updateItem={this.props.updateItem}
                  removeItem={this.props.removeItem}
                  item={item}
                  key={item.key}
                  itemIndex={itemIndex}
                  invoiceIndex={this.props.invoiceIndex}
                  currency={this.props.invoice.currency}
                  accounts={this.props.accounts}
                  taxes={this.props.taxes}
                  products={this.props.products}
                  tracking={this.props.tracking}
                  connectionData={this.props.connectionData}
                  taxCheckBox={isQboUs}
                  taxType={this.props.deal.settings.taxType}
                  serviceDateEnabled={this.props.preferences.serviceDateEnabled}
                  preferences={this.props.preferences}
                  isDragging={snapshot.isDragging}
                  customTaxes={this.props.invoice.customTaxes}
                  setCustomTaxes={value => this.props.updateInvoice(this.props.invoiceIndex, "customTaxes", value) }
                />
              </div>
            );
          }}
        </Draggable>
      );
    });

    let customFields = "";
    if (this.props.preferences.hasOwnProperty("customFields")) {
      customFields = this.props.preferences.customFields.map((customField, index) => {
        let classes =
          customField.id === "message"
            ? "flex flex-col px-2 basis-full"
            : "flex flex-col px-2 mb-2 basis-1/2";

        return (
          <div key={customField.id} className={classes}>
            <label className="flex flex-row flex-1 text-xs text-grey">
              <div className="flex-1 text-grey">{customField.label}</div>
              {this.props.invoiceIndex > 0 && (
                <label className="items-end self-end justify-center block text-xs text-grey">
                  Copy Value
                  <input
                    type="checkbox"
                    className="ml-2 accent-platform"
                    defaultChecked={this.props.invoice["useDefaultCustom_" + customField.id]}
                    onChange={(e) => {
                      this.props.updateInvoice(
                        this.props.invoiceIndex,
                        "useDefaultCustom_" + customField.id,
                        e.target.checked
                      );
                    }}
                  />
                </label>)}
            </label>
            {customField.id === "message" ? (
              <textarea
                className="p-1 text-sm border-b border-inherit focus:outline-none focus:border-platform"
                value={
                  this.props.invoice["useDefaultCustom_" + customField.id]
                    ? this.props.deal.settings["custom_" + customField.id]
                    : this.props.invoice["custom_" + customField.id]
                }
                disabled={
                  this.props.invoiceIndex > 0 &&
                  this.props.invoice["useDefaultCustom_" + customField.id]
                }
                onChange={(e) => {
                  this.props.updateInvoice(
                    this.props.invoiceIndex,
                    "custom_" + customField.id,
                    e.target.value
                  );
                  if (this.props.invoiceIndex === 0) {
                    this.props.updateSetting("custom_" + customField.id, e.target.value);
                  }
                }}
              ></textarea>
            ) : (
              <input
                type="text"
                className="p-1 text-sm border-b border-inherit focus:outline-none focus:border-platform"
                value={
                  this.props.invoice["useDefaultCustom_" + customField.id]
                    ? this.props.deal.settings["custom_" + customField.id]
                    : this.props.invoice["custom_" + customField.id]
                }
                disabled={
                  this.props.invoiceIndex > 0 &&
                  this.props.invoice["useDefaultCustom_" + customField.id]
                }
                onChange={(e) => {
                  this.props.updateInvoice(
                    this.props.invoiceIndex,
                    "custom_" + customField.id,
                    e.target.value
                  );
                  if (this.props.invoiceIndex === 0) {
                    this.props.updateSetting("custom_" + customField.id, e.target.value);
                  }
                }}
              />
            )}
          </div>
        );
      });
    }

    return (
      <>
        <div className="relative flex flex-col mb-8">
          <div
            className={classNames(
              this.props.error ? "bg-red-700" : "bg-platformmid",
              "flex w-full px-5 py-2 font-medium transition-all"
            )}
          >
            <div
              className={
                (connectionType === "qbo" && !this.props.error
                  ? "text-platform"
                  : "text-white") + " flex items-center flex-1 text-sm"
              }
            >
              {this.props.invoice?.external_id &&
                ["AUTHORISED", "PAID", "SENT", "VOIDED", "DELETED"].includes(
                  this.props.invoice.status
                ) && <LockClosedIcon className="inline-block w-4 h-4 mr-3"></LockClosedIcon>}
              Repeating Invoice
            </div>
            <div className="relative flex justify-end flex-1 gap-5 text-sm align-right">
              <InvoiceActions
                connectionType={connectionType}
                cloneAction={() => this.props.cloneInvoice(this.props.invoiceIndex)}
                deleteAction={() => this.props.removeInvoice(this.props.invoiceIndex)}
                deleteEnabled={!this.props.invoice.external_id}
                copyAction={() => this.copyToClipboard(this.props.invoice.external_url)}
                externalUrl={this.props.invoice.external_url}
                changeAction={() => this.changeToNonRecurring()}
                viewLink={this.getViewLink()}
                cancelEnabled={cancelEnabled}
                cancelAction={() => this.props.cancelInvoice(this.props.invoice.id)}
                replaceEnabled={replaceEnabled}
                replaceAction={() => {
                  this.props.replaceInvoice(this.props.invoice.id);
                }}
                refreshEnabled={!!this.props.invoice.external_id}
                refreshAction={() => this.props.refreshInvoice(this.props.invoice.external_id)}
              />
            </div>
          </div>
          <div
            className={`${
              locked ? "locked" : ""
            } border relative border-t-0 border-gray-200 hover:border-gray-300'}`}
          >
            {this.props.error && (
              <div className="flex items-center flex-1 py-1 pl-5 text-xs text-red-900 bg-red-200">
                Error Syncing: {this.props.error.message}
              </div>
            )}
            <div className="flex flex-row px-5 py-3 space-x-5 bg-gray-50">
              <div className="flex flex-col flex-1 w-full">
                <div className="flex gap-5 mb-2 max-w-min">
                  <div className="max-w-min ">
                    <label
                      className={
                        (this.props.invoice.hasNewStartDate ? "text-platform" : "text-grey") +
                        " block w-full text-xs"
                      }
                    >
                      Start Date
                    </label>
                    <input
                      className={
                        (this.props.invoice.hasNewStartDate && "text-platform") +
                        " flex text-xl font-semibold border-b bg-inherit border-inherit focus:outline-none focus:border-platform"
                      }
                      title="Add Date"
                      type="date"
                      placeholder="YYYY-MM-DD"
                      //defaultValue={this.props.invoice.date}
                      value={this.props.invoice.scheduleStartDate}
                      onChange={(e) => {
                        this.props.updateInvoice(
                          this.props.invoiceIndex,
                          "scheduleStartDate",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="max-w-min ">
                    <label className="block w-full text-xs text-grey">End Date</label>
                    <input
                      className="flex text-xl font-semibold border-b bg-inherit border-inherit focus:outline-none focus:border-platform"
                      title="Add Date"
                      type="date"
                      placeholder="YYYY-MM-DD"
                      //defaultValue={this.props.invoice.date}
                      value={this.props.invoice.scheduleEndDate}
                      onChange={(e) => {
                        this.props.updateInvoice(
                          this.props.invoiceIndex,
                          "scheduleEndDate",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                {this.props.invoice.hasNewStartDate && (
                  <span className="-mt-1 text-xs text-platform">
                    Start date updated to reflect next invoice date
                  </span>
                )}
                <div className="flex flex-row items-center gap-4 mt-2 flex-nowrap max-w-min">
                  <span className="block w-20 text-xs text-grey">Schedule</span>
                  <div className="text-sm whitespace-nowrap">
                    <div className="relative flex items-center gap-2">
                      <span className="flex-1 text-xs">Every</span>
                      <input
                        type="number"
                        className="flex-shrink p-1 text-sm border-b border-inherit focus:outline-none focus:border-platform"
                        value={this.props.invoice.schedulePeriod}
                        size="2"
                        min="1"
                        max="99"
                        onChange={(e) => {
                          this.props.updateInvoice(
                            this.props.invoiceIndex,
                            "schedulePeriod",
                            e.target.value
                          );
                        }}
                      />
                      <div className="relative inline-block w-32">
                        <select
                          onChange={(e) => {
                            this.props.updateInvoice(
                              this.props.invoiceIndex,
                              "scheduleUnit",
                              e.target.value
                            );
                          }}
                          value={this.props.invoice.scheduleUnit}
                          className="block w-full px-2 py-1 pr-8 text-sm leading-tight bg-white border border-gray-200 rounded-none appearance-none focus:border-platform focus:outline-none"
                        >
                          {connectionType === "qbo" && (
                            <option value="DAILY">
                              day{this.props.invoice.schedulePeriod !== "1" ? "s" : null}
                            </option>
                          )}
                          <option value="WEEKLY">
                            week{this.props.invoice.schedulePeriod !== "1" ? "s" : null}
                          </option>
                          <option value="MONTHLY">
                            month{this.props.invoice.schedulePeriod !== "1" ? "s" : null}
                          </option>
                        </select>
                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                          <svg
                            className="w-4 h-4 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.connectionData?.connectionType === "xero" && (
                <div className="flex flex-row items-center gap-4 mt-2 flex-nowrap max-w-min">
                  <span className="flex-shrink block w-20 text-xs text-grey">Due</span>
                  <div className="text-sm whitespace-nowrap">
                    <div className="relative flex items-center gap-3">
                      <span className="flex-shrink">
                        <input
                          type="number"
                          className="p-1 text-sm text-right border-b border-inherit focus:outline-none focus:border-platform"
                          value={this.props.invoice?.terms?.day}
                          min="1"
                          max="31"
                          onChange={(e) => {
                            this.props.updateInvoice(this.props.invoiceIndex, "terms", {
                              day: e.target.value,
                              type: this.props.invoice?.terms?.type,
                            });
                          }}
                        />
                        {this.props.invoice?.terms?.type === "OFCURRENTMONTH" ||
                        this.props.invoice?.terms?.type === "OFFOLLOWINGMONTH" ? (
                          <span className="ml-1 text-xs">
                            {this.getOrdinalNum(this.props.invoice?.terms?.day)}
                          </span>
                        ) : null}
                      </span>
                      <div className="relative inline-block w-48">
                        <select
                          onChange={(e) => {
                            this.props.updateInvoice(this.props.invoiceIndex, "terms", {
                              day: this.props.invoice?.terms?.day,
                              type: e.target.value,
                            });
                          }}
                          value={this.props.invoice?.terms?.type}
                          className="block w-full px-2 py-1 pr-8 text-sm leading-tight bg-white border border-gray-200 rounded-none appearance-none focus:border-platform focus:outline-none"
                        >
                          <option value="DAYSAFTERBILLDATE">
                            day{this.props.invoice?.terms?.day !== "1" ? "s" : null} after bill date
                          </option>
                          <option value="DAYSAFTERBILLMONTH">
                            day{this.props.invoice?.terms?.day !== "1" ? "s" : null} after bill
                            month
                          </option>
                          <option value="OFCURRENTMONTH">of the current month</option>
                          <option value="OFFOLLOWINGMONTH">of the following month</option>
                        </select>
                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                          <svg
                            className="w-4 h-4 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
                <div className="flex flex-row items-center gap-4 mt-2 flex-nowrap">
                  <span className="flex-shrink block w-20 text-xs text-grey">Status</span>
                  <div className="text-sm whitespace-nowrap">
                    {connectionType !== "qbo" &&
                    this.props.preferences.hasOwnProperty("statuses") &&
                    (this.props.preferences.statuses.allowed.includes(this.props.invoice.status) ||
                      this.props.invoice.status === "") ? (
                      <>
                        <div className="relative inline-block w-48">
                          <select
                            onChange={(e) => {
                              this.props.updateInvoice(
                                this.props.invoiceIndex,
                                "status",
                                e.target.value
                              );
                            }}
                            value={this.props.invoice.status}
                            className="block w-full px-2 py-1 pr-8 text-sm leading-tight bg-white border border-gray-200 rounded-none appearance-none focus:border-platform focus:outline-none"
                          >
                            {this.props.preferences.statuses.allowed.map((status) => {
                              if (status === "SUBMITTED") {
                                return <></>; // Not available on repeating
                              } else {
                                return (
                                  <option value={status} key={status}>
                                    {status === "DRAFT" && "Create as Draft"}
                                    {status === "APPROVED" && "Approve"}
                                    {status === "AUTHORISED" && "Approve and Send"}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                            <svg
                              className="w-4 h-4 fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {this.props.invoice.status === "AUTHORISED" && <div>Authorised</div>}
                        {this.props.invoice.status === "PAID" && <div>Paid</div>}
                        {this.props.invoice.status === "VOIDED" && <div>Voided</div>}
                        {this.props.invoice.status === "SUBMITTED" && <div>To Approve</div>}
                        {this.props.invoice.status === "DELETED" && <div>Deleted</div>}
                        {this.props.invoice.status === "DRAFT" && <div>Draft</div>}
                        {this.props.invoice.status === "" &&
                          this.props.invoice.external_id !== "" && <div>Draft</div>}
                        {this.props.invoice.status === "" &&
                          this.props.invoice.external_id === "" && <div>Ready to sync</div>}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap flex-1 h-full">{customFields}</div>
            </div>
            <div className="flex flex-col w-full px-4 pt-4 pb-2 bg-white">
              <Droppable droppableId={this.props.invoice.key}>
                {(provided, snapshot) => (
                  <div className="w-full" ref={provided.innerRef}>
                    <div className="relative">
                      <div className="flex flex-row border-b">
                        <div className="flex items-center justify-center w-4 bg-white cursor-grab">
                          <></>
                        </div>
                        {this.props.preferences.serviceDateEnabled && (
                          <span className="block w-32 text-xs text-grey">Service Date</span>
                        )}
                        {this.props.preferences.productsEnabled && (
                          <span className="block w-48 text-xs text-grey">Item</span>
                        )}
                        <span className="flex-1 block pl-1 mr-4 text-xs text-grey">
                          Description
                        </span>
                        {!this.props.invoice.customTaxes && !isQboUs && (
                          <span className="block w-32 ml-1 text-xs text-left text-grey">
                            Tax
                          </span>
                        )}
                        <span className="block w-12 pl-1 text-xs text-center text-grey">Qty</span>
                        <span className="block w-32 pl-1 text-xs text-right text-grey">Price</span>
                        {connectionType === "xero" && (
                          <span className="block w-16 ml-1 text-xs text-right text-grey">
                            Discount
                          </span>
                        )}
                        <span className="block w-32 pl-1 text-xs text-right text-grey ">
                          Subtotal
                        </span>
                        {isQboUs && (
                          <span className="block w-12 pl-1 text-xs text-center text-grey">Tax</span>
                        )}
                        <div className="w-12"></div>
                      </div>

                      {lineItemNode}

                      {provided.placeholder}

                      <button
                        className="absolute flex justify-center pt-1 text-sm underline text-platform"
                        onClick={this.addLineItem}
                      >
                        <PlusIcon className="inline w-4 mr-1" />
                        Add Line Item
                      </button>
                    </div>
                  </div>
                )}
              </Droppable>

              {this.props.invoice.creditIncTax > 0 ? (
                <div className="flex flex-row justify-end gap-2 my-4">
                  <div className="flex flex-col text-right">
                    <label className="text-xs text-grey">Credit</label>
                    <NumberDisplay
                      className="text-md"
                      value={-this.props.invoice.creditIncTax}
                      currency={this.props.invoice.currency}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.props.preferences.discountEnabled ? (
                <div className="flex flex-row justify-end gap-2 my-4">
                  {this.props.invoice.hasOwnProperty("discount") &&
                  this.props.invoice.discount.enabled ? (
                    <>
                      <div className="flex flex-col">
                        <label className="text-xs text-grey">Discount</label>
                        <input
                          type="text"
                          className="mr-2 border-b border-inherit focus:outline-none focus:border-platform"
                          placeholder="Value"
                          value={this.props.invoice.discount.value}
                          onChange={(e) => {
                            this.props.updateInvoice(this.props.invoiceIndex, "discount", {
                              value: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="flex flex-col text-right">
                        <span className="text-xs text-grey">Discount Total</span>

                        <NumberDisplay
                          className="text-md"
                          value={
                            this.props.invoice.discount.hasOwnProperty("total")
                              ? this.props.invoice.discount.total
                              : "pending"
                          }
                          currency={this.props.invoice.currency}
                        />
                      </div>
                    </>
                  ) : (
                    <button
                      className="flex justify-center py-1 pr-1 text-sm underline text-platform"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.updateInvoice(this.props.invoiceIndex, "discount", {
                          total: 0,
                          enabled: true,
                        });
                      }}
                    >
                      <PlusIcon className="inline w-4 ml-1 mr-1" />
                      Add Discount
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="flex flex-row w-full">
                <div className="flex flex-col justify-end flex-shrink">
                {!isQboUs || (isQboUs && this.hasEditableTax()) ? (
                    <>
                      {this.props.invoice.customTaxes ? (
                        <button
                          className="flex justify-center text-xs underline text-platform"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.setTaxAndAccountsToDealSettings(this.props.invoiceIndex);
                            this.props.updateInvoice(this.props.invoiceIndex, "customTaxes", false);
                          }}
                        >
                          {connectionType === "qbo" ? "Use Default Taxes" : "Use Default Accounts and Taxes"}
                        </button>
                      ) : (
                        <button
                          className="flex justify-center text-xs underline text-platform"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.resetAccounts(this.props.invoiceIndex);
                            this.props.updateInvoice(this.props.invoiceIndex, "customTaxes", true);
                          }}
                        >
                          {connectionType === "qbo" ? "Customise Taxes" : "Customise Accounts and Taxes"}
                        </button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex flex-col items-end justify-end flex-grow gap-1 text-right">
                  <label className="flex items-center gap-5 mt-2 invoice_total">
                    <span className="text-xs text-grey">Subtotal</span>
                    <NumberDisplay
                      className="text-3xl font-semibold"
                      value={this.props.invoice.total}
                      currency={this.props.invoice.currency}
                    />
                  </label>

                  {isQboUs && this.hasEditableTax() && this.props.invoice.customTaxes && <TaxSelect
                      value={taxList.find((x) => x.value === this.props.invoice.taxType)}
                      list={taxList}
                      update={this.updateTax}
                      disabled={false}
                      country={this.props.connectionData?.country}
                      horizontal={true}
                      platformStyles={this.props.platformStyles}
                    />
                  }

                  {this.totalIncTaxComponent()}
                </div>
              </div>
            </div>
          </div>

          {this.props.invoice?.linked_invoices.length || this.props.invoice?.previous_invoice ? (
            <div className="p-3 border-b border-l border-r border-gray-200">
              <h3 className="flex items-center gap-2 text-grey">
                <span className="bg-platform text-white inline-block py-0.5 px-3 text-xs font-medium rounded-full">
                  {allLinkedInvoicesCount}
                </span>

                <span className="flex flex-row text-sm">
                  Invoice{allLinkedInvoicesCount > 1 ? "s" : null} created, total{" "}
                  <NumberDisplay
                    value={allLinkedInvoicesTotal}
                    currency={this.props.invoice.currency}
                    className="ml-1 text-sm"
                  />
                </span>

                <button
                  className="text-xs underline text-platform"
                  onClick={(e) => this.setState({ showInvoices: !this.state.showInvoices })}
                >
                  {this.state.showInvoices ? "Hide" : "Show"}
                </button>
              </h3>

              {this.state.showInvoices ? (
                <div className="p-3">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th className="py-2 pl-4 pr-3 text-xs font-semibold text-left text-gray-900 sm:pl-6 md:pl-0">
                            Invoice Number
                          </th>
                          <th className="px-3 py-2 text-xs font-semibold text-left text-gray-900">
                            Date
                          </th>
                          <th className="px-3 py-2 text-xs font-semibold text-left text-gray-900">
                            Due
                          </th>
                          <th className="px-3 py-2 text-xs font-semibold text-left text-gray-900">
                            Status
                          </th>
                          <th className="px-3 py-2 text-xs font-semibold text-left text-gray-900">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {this.props.invoice?.linked_invoices.map((invoice, index) => (
                          <LinkedInvoice
                            invoice={invoice}
                            key={index}
                            shortCode={this.props.shortCode}
                          />
                        ))}
                      </tbody>

                      {this.getPreviousInvoice(this.props.invoice)}
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default RepeatingInvoice;
