import React from "react";

class DiscountField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  formatDiscountValue(number) {
    if (!number) {
      return "";
    }
    if (typeof number === "string" && number.includes("%")) {
      return number;
    } else {
      let code = typeof this.props.currency === "undefined" ? "GBP" : this.props.currency;
      const formatter = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: code,
      });
      return formatter.format(number);
    }
  }

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    let value = 0;
    if (this.props.discountAmount && this.props.discountAmount >= 0) {
      value = this.props.discountAmount;
    } else if (this.props.discountRate) {
      value = this.props.discountRate + "%";
    }
    return (
      <div className={this.props.className}>
        {this.state.isEditing ? (
          <input
            type="text"
            value={value === 0 ? "" : value}
            onChange={this.onChange.bind(this)}
            onBlur={this.toggleEditing.bind(this)}
            className="w-full p-1 text-sm text-right border-b focus:outline-none focus:border-platform"
          />
        ) : (
          <input
            type="text"
            value={this.formatDiscountValue(value)}
            onFocus={this.toggleEditing.bind(this)}
            readOnly
            className="w-full p-1 text-sm text-right border-b"
          />
        )}
      </div>
    );
  }
}

export default DiscountField;
