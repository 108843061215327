export default function LoadingState({ h1 = "Loading..." }) {
  return (
    <div className="fixed top-0 left-0 z-50 flex flex-col justify-center w-screen h-screen bg-white">
      <div className="flex items-center h-48 justify-contents">
        <span className="flex flex-col items-center flex-auto">
          <span className="flex-auto m-3">
            <img src="invoice-anim-v2.svg"/>
          </span>
          <span className="flex-auto text-base text-gray-800 ">{h1}</span>
        </span>
      </div>
    </div>
  );
}
