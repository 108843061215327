import React from "react";

class NumberField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  toCurrency(number) {
    try {
      if (!number) {
        return "";
      } 
      const formatter = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: this.props.currency,
        maximumFractionDigits: 4
      });
      return formatter.format(number);
    } catch (error) {
      //console.log(error);
      return number;
    }
  }

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing });
  }

  round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }

  iOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  render() {
    return (
      <div className={this.props.className}>
      {(this.state.isEditing || this.iOS()) ? (
          <input
            type="number"
            step=".01"
            value={this.props.value === 0 ? "" : this.round(this.props.value, 4)}
            onChange={this.onChange.bind(this)}
            onBlur={this.toggleEditing.bind(this)}
            className="w-full p-1 text-sm text-right border-b rounded-none focus:outline-none focus:border-platform"
          />
        ) : (
          <input
            type="text"
            value={this.toCurrency(this.props.value)}
            onFocus={this.toggleEditing.bind(this)}
            readOnly
            className="w-full p-1 text-sm text-right border-b rounded-none"
          />
        )}
      </div>
    );
  }
}

export default NumberField;
