import React from "react";
import NumberField from "./fields/NumberField";
import DiscountField from "./fields/DiscountField";
import ProductField from "./fields/ProductField";
import LineItemActions from "./menus/LineItemActions";

import { MenuIcon } from "@heroicons/react/solid";
import autosize from "autosize";
import { ChevronDownIcon } from "@heroicons/react/outline";


class LineItem extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    autosize(this.textarea);
  }
  updateTax(taxType) {
    if (this.props.taxes && this.props.taxes.length > 0) {
      this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "taxType", taxType);
      let tax = this.props.taxes.find((x) => x.type === taxType);
      if (typeof tax == "undefined") {
        tax = {};
        tax.rate = 0;
      } else if (!tax.hasOwnProperty("rate")) {
        tax.rate = 0;
      }
      this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "tax", tax.rate);
    }
  }
  updateAccount(accountCode) {
    if (this.props.accounts && this.props.accounts.length > 0) {
      if (accountCode !== "NONE") {
        let account = this.props.accounts.find((x) => x.code === accountCode);
        if (typeof account !== "undefined") {
          let tax = this.props.taxes.find((x) => x.type === account.taxType);
          if (typeof tax !== "undefined" && !this.props.item.taxType) {
            this.updateTax(tax.type);
          }
          this.props.updateItem(
            this.props.invoiceIndex,
            this.props.itemIndex,
            "account",
            accountCode
          );
        } else {
          this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "account", "NONE");
        }
      } else {
        this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "account", "NONE");
      }
    }
  }
  updateTracking(categoryName,optionName) {
      let trackingData = (this.props.item.tracking) ? this.props.item.tracking : {};
      if(trackingData.hasOwnProperty(categoryName)) {
          delete trackingData.categoryName;
      }
      trackingData[categoryName] = optionName;
      this.props.updateItem(this.props.invoiceIndex,this.props.itemIndex,'tracking',trackingData);
  }
  render() {    
    const { country, connectionType } = this.props.connectionData;
    const isQboUs = country === "US" && connectionType === "qbo";

    let itemClasses = ["item z-50 overflow-visible flex flex-row border-l border-r"];
    if (this.props.itemIndex === 0) {
      itemClasses.push("item-first");
    }
    if (this.props.hasDiscounts) {
      itemClasses.push("item-has-discount");
    }
    if (this.props.item.error) {
      itemClasses.push("item-error");
    }
    return (
      <>
        <div className={itemClasses.join(" ")}>
          <div className="flex items-center justify-center w-4 bg-white border-b cursor-grab">
            <MenuIcon className="w-3 h-3 text-gray-300" />
          </div>
          {this.props.serviceDateEnabled && (
            <label className="border-r min-w-32">
              <input
                className="w-full h-full p-1 text-sm text-left bg-white border-b rounded-none appearance-none placeholder:text-gray-400 focus:outline-none focus:border-platform"
                title="Add Date"
                type="date"
                placeholder="YYYY-MM-DD"
                value={this.props.item.serviceDate}
                onChange={(e) => {
                  this.props.updateItem(
                    this.props.invoiceIndex,
                    this.props.itemIndex,
                    "serviceDate",
                    e.target.value
                  );
                }}
              />
            </label>
          )}
          {this.props.preferences.productsEnabled && (
            <label className="w-48 border-b border-r">
              <div className="flex items-center h-full text-xs item-combo">
                <ProductField
                  sku={this.props.item.sku ? this.props.item.sku : ""}
                  products={this.props.products}
                  reference={this.props.item.reference ? this.props.item.reference : ""}
                  description={this.props.item?.description}
                  itemLabel={this.props.item.itemLabel ? this.props.item.itemLabel : ""}
                  updateItem={this.props.updateItem}
                  updateTax={this.updateTax.bind(this)}
                  invoiceIndex={this.props.invoiceIndex}
                  itemIndex={this.props.itemIndex}
                  price={this.props.item.price}
                  settings={this.props.settings}
                  setCustomTaxes={this.props.setCustomTaxes}
                  connectionData={this.props.connectionData}
                />
              </div>
            </label>
          )}
          <div className="relative flex flex-wrap flex-grow w-4 lg:justify-end">
            <textarea
              className="focus:outline-none peer px-2 py-2 flex-1 h-fit min-w-[100%] lg:min-w-[50%] xl:min-w-[30%]"
              required
              type="text"
              ref={(x) => (this.textarea = x)}
              rows="1"
              value={this.props.item.description}
              onChange={(e) => {
                this.props.updateItem(
                  this.props.invoiceIndex,
                  this.props.itemIndex,
                  "description",
                  e.target.value
                );
              }}
            ></textarea>
            <div className="absolute bottom-0 left-0 w-full h-0 border-b peer-focus:border-platform"></div>
            {this.props.customTaxes &&
              this.props.preferences?.trackingByLine &&
              this.props?.tracking && (
                <>
                  {this.props.tracking.map((category,index) => (
                    <div className="relative min-w-[50%] lg:min-w-[6em] lg:max-w-[8em]" key={index}>
                      <select
                        onChange={(e) => {
                          this.updateTracking(category.name, e.target.value);
                        }}
                        className="block w-full py-1 pl-2 pr-3 text-xs leading-tight bg-transparent border-none rounded-none appearance-none text-grey lg:py-3 text-ellipsis focus:outline-none"
                        value={
                          this.props.item?.tracking &&
                          this.props.item.tracking.hasOwnProperty(category.name) &&
                          this.props.item.tracking[category.name]
                        }
                      >
                        <option
                          key="NULL"
                          value={false}
                          disabled
                          selected={
                            !this.props.item?.tracking ||
                            !this.props.item.tracking.hasOwnProperty(category.name) ||
                            this.props.item.tracking[category.name]
                          }
                        >
                          {category.name}
                        </option>
                        {category.options.map((option) => (
                          <option
                            key={"category_" + option.Name}
                            value={option.Name}
                            className="text-ellipsis"
                          >
                            {option.Name}
                          </option>
                        ))}
                      </select>
                      <ChevronDownIcon className="absolute right-0 w-3 h-3 bg-white rounded-full pointer-events-none text-grey top-1 lg:top-3" />
                    </div>
                  ))}
                </>
              )}

            {!isQboUs && this.props.customTaxes && this.props?.accounts && (
              <div className="relative  min-w-[50%] lg:min-w-[6em] max-w-[8em]">
                <select
                  onChange={(e) => {
                    this.updateAccount(e.target.value);
                  }}
                  className="block w-full py-1 pl-2 pr-3 text-xs leading-tight bg-transparent border-none rounded-none appearance-none text-grey lg:py-3 text-ellipsis focus:outline-none"
                  value={this.props.item.account}
                  disabled={connectionType === "qbo"}
                >
                  <option key="NONE" value="NONE" disabled selected={!this.props.item.account}>
                    {connectionType === "qbo" ? "No Account" : "Account"}
                  </option>
                  {this.props.accounts.map((account) => (
                    <option
                      key={"account_" + account.code}
                      value={account.code}
                      className="text-ellipsis"
                    >
                      {account.name}
                    </option>
                  ))}
                </select>
                {connectionType === "qbo" ||
                  <ChevronDownIcon className="absolute right-0 w-3 h-3 bg-white rounded-full pointer-events-none text-grey top-1 lg:top-3" />
                }
              </div>
            )}

            {this.props.customTaxes && !this.props.taxCheckBox && this.props?.accounts && (
              <div className="relative  min-w-[50%] lg:min-w-[6em] max-w-[8em]">
                <select
                  onChange={(e) => {
                    this.updateTax(e.target.value);
                  }}
                  value={this.props.item.taxType}
                  className="block w-full py-1 pl-2 pr-3 text-xs leading-tight bg-transparent border-none rounded-none appearance-none text-grey lg:py-3 focus:outline-none"
                >
                  <option key="NULL" value={false} disabled selected={!this.props.item.taxType}>
                    Tax
                  </option>
                  {this.props.taxes.map((tax) => (
                    <option key={"tax_" + tax.type} value={tax.type}>
                      {tax.name}
                    </option>
                  ))}
                </select>
                <ChevronDownIcon className="absolute right-0 w-3 h-3 bg-white rounded-full pointer-events-none text-grey top-1 lg:top-3" />
              </div>
            )}

            {!this.props.customTaxes && !this.props.taxCheckBox && (
              <div className="flex items-center w-32 pl-1 border-b">
                <span className="text-xs text-grey">
                  {this.props.taxes &&
                    this.props.taxes.find((x) => x.type === this.props.item.taxType) &&
                    this.props.taxes.find((x) => x.type === this.props.item.taxType).rate + "%"}
                </span>
              </div>
            )}
          </div>
          <input
            className="w-12 pl-1 text-sm text-right border-b rounded-none items-top focus:outline-none focus:border-platform"
            required
            min="0"
            type="number"
            value={this.props.item.qty}
            onChange={(e) => {
              this.props.updateItem(
                this.props.invoiceIndex,
                this.props.itemIndex,
                "qty",
                e.target.value
              );
            }}
          />
          <NumberField
            currency={this.props.currency}
            value={this.props.item.price}
            className="flex w-32 pl-1"
            onChange={(e) => {
              this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "price", e);
            }}
          />
          {connectionType === "xero" && (
            <DiscountField
              discountAmount={this.props.item.discountAmount}
              discountRate={this.props.item.discountRate}
              currency={this.props.currency}
              className="flex w-16 pl-1"
              onChange={(e) => {
                this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "discount", e);
              }}
            />
          )}
          <NumberField
            currency={this.props.currency}
            value={this.props.item.subtotal}
            className="flex w-32 pl-1"
            onChange={(e) => {
              this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "subtotal", e);
            }}
          />
          {this.props.taxCheckBox && (
            <div className="flex items-center justify-center w-12 border-b">
              <input
                className="accent-platform"
                required
                type="checkbox"
                checked={this.props.item.taxType === "TAX"}
                onChange={(e) => {
                  this.props.updateItem(
                    this.props.invoiceIndex,
                    this.props.itemIndex,
                    "taxType",
                    e.target.checked ? "TAX" : "NON"
                  );
                }}
              />
            </div>
          )}
          <div className="flex items-center justify-center w-12 border-b">
            <LineItemActions
              cloneAction={(e) =>
                this.props.cloneItem(this.props.invoiceIndex, this.props.itemIndex)
              }
              deleteAction={(e) =>
                this.props.removeItem(this.props.invoiceIndex, this.props.itemIndex)
              }
            />
          </div>
        </div>

        {this.props.item.errorDetails && (
          <div className="z-50 w-full p-1 text-xs text-red-600 bg-white border-b border-l border-r">
            Line Item Error: {this.props.item.errorDetails}
          </div>
        )}
      </>
    );
  }
}

export default LineItem;
