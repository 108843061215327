/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationIcon,
  DocumentDownloadIcon, XIcon,
  UserIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import { ExternalLinkIcon } from "@heroicons/react/solid";

export default function SendInvoice({ open, setOpen, invoiceId, contactId, externalUrl, platformStyles, sent, connectionType, setError, setSuccess, toggleEditContact}) {
  const cancelButtonRef = useRef();
  const [emails, setEmails] = useState(0);
  const [loading, setLoading] = useState(false);
  let api = process.env.REACT_APP_API_ROOT;
  const accessToken = new URLSearchParams(document.location.search).get("access_token");

  useEffect(() => {
    (async () => {
      if (open && contactId) {
        await fetch(api + "/contacts/getContact/" + contactId, {
          headers: { "Content-Type": "application/json", accesstoken: accessToken },
          method: "get",
        })
          .then((res) => res.json())
          .then((res) => {
            let contact = res.contact[0];
            let emailData = contact.emails;
            setEmails(emailData);
          })
          .catch((error) => {
            setError("Error getting contact emails");
            console.error(error);
          });
      }
    })();
  }, [open, contactId]);
  
  const sendInvoice = () => {
    setLoading(true);
    fetch(api + "/invoice/send/" + invoiceId, {
      headers: { "Content-Type": "application/json", accesstoken: accessToken },
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setOpen(false);
        setSuccess("Invoice Sent")
        console.log("done");
      })
      .catch((error) => {
        setError("Error sending invoice");
        setLoading(false);
        console.error(error);
      });
  };
 
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={setOpen}
        style={platformStyles}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-white bg-opacity-90"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white border rounded-lg shadow-xl sm:max-w-2xl sm:my-8 sm:align-middle sm:w-full sm:p-6">
              <div>
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Send Invoice
                  </Dialog.Title>
                  <div className="flex flex-col w-4/5 gap-4 px-10 py-5 mx-auto mt-2 text-sm text-left">
                    <div className="grid grid-cols-4">
                      <div className="inline-block w-[120px] text-xs text-grey">To:</div>
                      <div className="col-span-3">
                        {emails ? emails.map((e, i) => <div key={i}>{e}</div>) : null}
                        {connectionType === "xero" && (
                          <button
                            className="underline text-platform"
                            onClick={() => {
                              setOpen(false);
                              toggleEditContact();
                            }}
                          >
                            Edit emails
                          </button>
                        )}
                      </div>
                    </div>
                    {connectionType === "xero" ? (
                      <>
                        <div className="grid grid-cols-4">
                          <div className="inline-block w-[120px] text-xs text-grey">From:</div>
                          <div className="col-span-3">Xero</div>
                        </div>
                        <div className="grid grid-cols-4">
                          <span className="inline-block w-[120px] text-xs text-grey">
                            Contents:
                          </span>
                          <span className="col-span-3">
                            This invoice will be sent using the email template settings in your Xero
                            account
                            <br />
                            <a
                              href={externalUrl}
                              target="_blank"
                              className="inline-flex gap-2 py-2 pl-5 pr-3 mt-4 border-2 rounded-md text-platform border-platform"
                            >
                              Preview Invoice
                              <ExternalLinkIcon className="w-5 h-5" />
                            </a>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="grid grid-cols-4">
                          <div className="inline-block w-[120px] text-xs text-grey">From:</div>
                          <div className="col-span-3">QuickBooks</div>
                        </div>
                        <div className="grid grid-cols-4">
                          <span className="inline-block w-[120px] text-xs text-grey">
                            Contents:
                          </span>
                          <span className="col-span-3">
                            This invoice will be sent using the email template settings in your
                            QuickBooks account
                            <br />
                            <a
                              href={
                                api +
                                "/invoice/getPDF/" +
                                invoiceId +
                                "?access_token=" +
                                accessToken
                              }
                              target="_blank"
                              className="inline-flex gap-2 py-2 pl-5 pr-3 mt-4 border-2 rounded-md text-platform border-platform"
                            >
                              Preview PDF Invoice
                              <DocumentDownloadIcon className="w-5 h-5" />
                            </a>
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="px-10">
                    {!loading ? (
                      <button
                        type="button"
                        className="px-6 py-3 text-white rounded-md bg-platform"
                        onClick={() => sendInvoice()}
                      >
                        Send Invoice
                      </button>
                    ) : (
                      <button type="button" className="px-6 py-3 text-white rounded-md bg-platform">
                        Sending...
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
