import React from "react";

class ActionButton extends React.Component {
  render() {
    return (
      <button onClick={this.props.action} className={this.props.className}>
        {this.props.text}
      </button>
    );
  }
}

export default ActionButton;
