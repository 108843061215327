import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {
  LockClosedIcon,
  PlusIcon,
  ChevronRightIcon,
  CheckCircleIcon as CheckCircleIconSolid,
} from "@heroicons/react/solid";
import {
  ClipboardCheckIcon,
  ClipboardCopyIcon,
  ExclamationIcon,
  DuplicateIcon,
  MailIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";

import LineItem from "./LineItem";
import NumberDisplay from "./fields/NumberDisplay";
import TaxSelect from "./fields/TaxSelect";
import InvoiceActions from "./menus/InvoiceActions";
import SendInvoice from "./modals/SendInvoice";

let api = process.env.REACT_APP_API_ROOT;
const accessToken = new URLSearchParams(document.location.search).get("access_token");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Invoice extends React.Component {
  // TODO - minimise usage of this.props in render
  constructor(props) {
    super(props);
    this.state = {
      hasDiscounts: false,
      sendInvoice: false,
      isCollapsed: true,
    };
  }

  async markSent() {
    try {
      const response = await fetch(api + "/invoice/markSent/" + this.props.invoice.id, {
        headers: { "Content-Type": "application/json", accesstoken: accessToken },
        method: "get",
      });

      const json = await response.json();
      
      if (json.error) {
        throw new Error(json.error);
      }
      this.props.updateInvoice(this.props.invoiceIndex, "sent", true);
      this.props.setSuccess("Marked as sent");
    } catch (error) {
      console.log(error);
      this.props.setError("Could not mark as sent");
    }
  }
  
  sendInvoiceSuccess() {
    this.props.updateInvoice(this.props.invoiceIndex, "sent", true);
    this.props.setSuccess('Invoice sent');
  }
  copyToClipboard(data) {
    // Using deprecated document.execCommand because of permissions on parent iframe
    var textField = document.createElement("textarea");
    textField.innerText = this.props.invoice.external_url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.props.setSuccess("Copied to clipboard");
  }

  changeToNonRecurring() {
    this.props.updateSetting("mode", "Custom");
  }

  getViewLink = () => {
    const invoice = this.props.invoice;
    const connectionType = this.props.connectionData?.connectionType;

    if (connectionType === "xero" && this.props?.shortCode && invoice?.external_id) {
      return `https://go.xero.com/organisationlogin/default.aspx?shortcode=${this.props.shortCode}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${invoice.external_id}`;
    }

    if (connectionType === "qbo" && invoice?.external_id) {
      return `https://app.qbo.intuit.com/app/invoice?txnId=${invoice.external_id}`
    }
    

    return false;
  }

  getPDFLink = () => { 
    if(this.props.invoice?.external_id) {
      return `${api}/invoice/getPDF/${this.props.invoice.id}?access_token=${accessToken}`;
    }
    return false;
  }

  totalIncTaxComponent = () => {
    const invoice = this.props.invoice;
    const taxNeedsCalculating = this.hasEditableTax() && invoice.taxType === 'TAX' && invoice.totalTax === 0;

    if (taxNeedsCalculating) {
      return (
        <label className="flex items-center gap-5 text-right">
          <span className="text-xs text-grey">Total inc. Tax</span>
          <span>Automated</span>
        </label>
      );
    }

    if (invoice.totalIncTax !== invoice.total) {
      return (
        <label className="flex items-center gap-5 text-right">
          <span className="text-xs text-grey">Total inc. Tax</span>
          <NumberDisplay
            className="w-24"
            value={this.props.invoice.totalIncTax}
            currency={this.props.invoice.currency}
          />
        </label>
      );
    }
  }

  addLineItem = (e) => {
    e.preventDefault();

    const { country, connectionType } = this.props.connectionData;
    const isQboUs = country === "US" && connectionType === "qbo";

    const data = {
      tax: this.props.invoice.customTaxes ? 0 : this.props.deal.settings?.tax || 0,
      taxType: isQboUs ? "NON" : this.props.invoice.customTaxes ? false : this.props.deal.settings?.taxType || false,
      account: this.props.invoice.customTaxes ? "NONE" : this.props.deal.settings?.account ||"NONE"
    }
    
    this.props.addItem(this.props.invoiceIndex, data);
  }

  getTaxList = (isQboUs) => {
    const taxes = this.props?.taxes;

    if (!taxes) {
      return [];
    }

    let taxList = taxes.map((x) => {
      return { value: x.type, label: x.name };
    });

    if (isQboUs) {
      taxList = [...[{ value: "TAX", label: "Automated" }], ...taxList];
    } else {
      taxList = [...[{ value: false, label: "Not Set" }], ...taxList];
    }

    return taxList;
  }

  updateTax = (value) => {
    // This tax type is for US connections only and means QBO will
    // Auto-calculate based on location.
    if (value === 'TAX') {
      this.props.updateInvoice(this.props.invoiceIndex, "taxType", value);
      this.props.updateInvoice(this.props.invoiceIndex, "totalTax", 0);
    } else {
      const tax = this.props.taxes.find((x) => x.type === value);
      this.props.updateInvoice(this.props.invoiceIndex, "taxType", tax.type);
    }
  }

  hasEditableTax = () => {
    const invoice = this.props.invoice;

    if (["AUTHORISED", "PAID", "SENT", "VOIDED", "DELETED"].includes(invoice.status)) {
      return false;
    }

    return invoice.line_items.some(item => item.taxType === 'TAX');
  }

  render() {
    const { connectionType, country } = this.props?.connectionData;
    const isQboUs = connectionType === "qbo" && country === "US";

    const taxList = this.getTaxList(isQboUs);

    const lineItemsList = this.props.invoice.line_items.map((item, itemIndex) => {
      return (
        <Draggable key={item.key} draggableId={item.key.toString()} index={itemIndex}>
          {(provided, snapshot) => {
            const style = {
              borderTopColor: snapshot.isDragging ? "#e5e7eb " : "",
              borderTopWidth: snapshot.isDragging ? "1px" : "",
              ...provided.draggableProps.style,
            };
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={style}
              >
                <LineItem
                  cloneItem={this.props.cloneItem}
                  updateItem={this.props.updateItem}
                  removeItem={this.props.removeItem}
                  item={item}
                  key={item.key}
                  itemIndex={itemIndex}
                  invoiceIndex={this.props.invoiceIndex}
                  currency={this.props.invoice.currency}
                  accounts={this.props.accounts}
                  taxes={this.props.taxes}
                  products={this.props.products}
                  tracking={this.props.tracking}
                  connectionData={this.props.connectionData}
                  taxCheckBox={isQboUs}
                  serviceDateEnabled={this.props.preferences.serviceDateEnabled}
                  preferences={this.props.preferences}
                  isDragging={snapshot.isDragging}
                  settings={this.props.deal.settings}
                  customTaxes={this.props.invoice.customTaxes}
                  setCustomTaxes={value => this.props.updateInvoice(this.props.invoiceIndex, "customTaxes", value) }
                />
              </div>
            );
          }}
        </Draggable>
      );
    });
  
    let invoiceType = 'Invoice';
    if(this.props.deal.settings.mode === "recurring" || this.props.deal.settings.mode === "Monthly") {
       invoiceType =
         this.props.invoiceIndex === 0 ? "Recurring Invoice (Primary)" : "Recurring Invoice Copy";
    }
    if(this.props.deal.settings.mode === "deposit_recurring") {
       invoiceType =
         this.props.invoiceIndex <= 1
           ? this.props.invoiceIndex === 0
             ? "Deposit Invoice"
             : "Recurring Invoice (Primary)"
           : "Recurring Invoice Copy";
    }

    let customFields;
    if (this.props.preferences.hasOwnProperty("customFields")) {
      customFields = this.props.preferences.customFields.map((customField, index) => {
        let classes =
          customField.id === "message"
            ? "flex flex-col px-2 basis-full"
            : "flex flex-col px-2 mb-2 basis-1/2";
            
        return (
          <div key={customField.id} className={classes}>
            <label className="flex flex-row flex-1 text-xs text-grey">
              <div className="flex-1 text-grey">{customField.label}</div>
              {this.props.invoiceIndex > 0 && !this.props.invoice?.external_id ? (
                <label className="items-end self-end justify-center block text-xs text-grey">
                  Copy Value
                  <input
                    type="checkbox"
                    className="ml-2 accent-platform"
                    defaultChecked={this.props.invoice["useDefaultCustom_" + customField.id]}
                    onChange={(e) => {
                      this.props.updateInvoice(
                        this.props.invoiceIndex,
                        "useDefaultCustom_" + customField.id,
                        e.target.checked
                      );
                    }}
                  />
                </label>
              ) : (
                ""
              )}
            </label>
            {customField.id === "message" ? (
              <textarea
                className="p-1 text-sm border-b border-inherit focus:outline-none focus:border-platform"
                value={
                  this.props.invoice["useDefaultCustom_" + customField.id] &&
                  !this.props.invoice?.external_id
                    ? this.props.deal.settings["custom_" + customField.id]
                    : this.props.invoice["custom_" + customField.id]
                }
                disabled={
                  this.props.invoiceIndex > 0 &&
                  this.props.invoice["useDefaultCustom_" + customField.id] &&
                  !this.props.invoice?.external_id
                }
                onChange={(e) => {
                  this.props.updateInvoice(
                    this.props.invoiceIndex,
                    "custom_" + customField.id,
                    e.target.value
                  );
                  if (this.props.invoiceIndex === 0) {
                    this.props.updateSetting("custom_" + customField.id, e.target.value);
                  }
                }}
              ></textarea>
            ) : (
              <input
                type="text"
                className="p-1 text-sm border-b border-inherit focus:outline-none focus:border-platform"
                value={
                  this.props.invoice["useDefaultCustom_" + customField.id] &&
                  !this.props.invoice?.external_id
                    ? this.props.deal.settings["custom_" + customField.id]
                    : this.props.invoice["custom_" + customField.id]
                }
                disabled={
                  this.props.invoiceIndex > 0 &&
                  this.props.invoice["useDefaultCustom_" + customField.id] &&
                  !this.props.invoice?.external_id
                }
                onChange={(e) => {
                  this.props.updateInvoice(
                    this.props.invoiceIndex,
                    "custom_" + customField.id,
                    e.target.value
                  );
                  if (this.props.invoiceIndex === 0) {
                    this.props.updateSetting("custom_" + customField.id, e.target.value);
                  }
                }}
              />
            )}
          </div>
        );
      });
    }

    let isLocked, isCollapsible = false;
    if(this.props.invoice?.external_id && ["AUTHORISED", "PAID", "SENT", "VOIDED", "DELETED"].includes(this.props.invoice.status)) {
      isLocked = true;
    } else if(invoiceType === "Recurring Invoice Copy") {
      isLocked = true;
    }
    if(this.props.invoice?.external_id && ["VOIDED", "DELETED"].includes(this.props.invoice.status)) {
      isCollapsible = true;
    }
  
    const statusMapping = {
      AUTHORISED: "Authorised",
      PAID: "Paid",
      VOIDED: "Voided",
      SENT: "Sent",
      SUBMITTED: "To Approve",
      DELETED: "Deleted",
      DRAFT: "Draft",
    };

    const allowSend = (this.props.invoice?.external_url || (connectionType === "qbo" && this.props.invoice?.external_id)) && this.props.preferences?.financeMode === false;
    
    return (
      <>
        <SendInvoice
          open={this.state.sendInvoice}
          setOpen={(e) => this.setState({ sendInvoice: false })}
          invoiceId={this.props.invoice.id}
          contactId={this.props.deal.settings.contact}
          externalUrl={this.props.invoice.external_url}
          platformStyles={this.props.platformStyles}
          sent={this.props.invoice.sent}
          connectionType={connectionType}
          setError={this.props.setError}
          setSuccess={this.sendInvoiceSuccess.bind(this)}
          toggleEditContact={this.props.toggleEditContact}
        />
        <div className="relative flex flex-col mb-8">
          <div
            className={classNames(
              this.props.error ? "bg-red-700" : "bg-platform",
              isCollapsible && "cursor-pointer",
              "flex w-full px-5 py-2 font-medium transition-all"
            )}
            onClick={(e) => {
              if (isCollapsible) {
                this.setState({ isCollapsed: !this.state.isCollapsed });
              }
            }}
          >
            <div className="flex items-center flex-1 text-sm text-white">
              {isCollapsible && (
                <ChevronRightIcon
                  className={classNames(
                    !this.state.isCollapsed && "rotate-90",
                    "inline-block w-5 h-5 mr-3 -ml-3"
                  )}
                ></ChevronRightIcon>
              )}
              {isLocked && <LockClosedIcon className="inline-block w-4 h-4 mr-3"></LockClosedIcon>}
              {invoiceType}
              {isCollapsible && <> - {statusMapping[this.props.invoice.status]}</>}
            </div>
            <div className="relative flex justify-end flex-1 gap-5 text-sm align-right">
              <div className="text-white ">
                {(connectionType === "qbo" && this.props.invoice.external_invoicenumber) && "#"}
                {this.props.invoice.external_invoicenumber}
              </div>
              <InvoiceActions
                connectionType={connectionType}
                cloneAction={() => this.props.cloneInvoice(this.props.invoiceIndex)}
                deleteAction={() => this.props.removeInvoice(this.props.invoiceIndex)}
                deleteEnabled={!this.props.invoice.external_id}
                viewLink={this.getViewLink()}
                pdfLink={this.getPDFLink()}
                copyAction={() => this.copyToClipboard(this.props.invoice.external_url)}
                externalUrl={this.props.invoice.external_url}
                changeAction={() => this.changeToNonRecurring()}
                changeEnabled={invoiceType === "Recurring Invoice Copy"}
                invoiceIndex={this.props.invoiceIndex}
                splits={this.props.preferences?.splits}
                splitAction={this.props.splitInvoice}
                refreshEnabled={!!this.props.invoice.external_id}
                refreshAction={() => this.props.refreshInvoice(this.props.invoice.external_id)}
              />
            </div>
          </div>
          {allowSend && (
            <div className="relative flex items-center flex-1 py-1 pl-5 pr-4 border border-t-0 border-gray-200">
              <div className="flex-1 text-sm font-semibold ">
                {this.props.invoice?.sent ? "Invoice has been sent" : "Invoice ready to send"}
              </div>
              <div className="flex items-center gap-2">
                <button
                  className="flex items-center gap-2 py-1 pl-1 pr-2 text-xs transition-all border-2 rounded-md lg:pr-5 lg:pl-3 lg:py-2 text-platform border-platform hover:bg-gray-50"
                  onClick={(e) => this.setState({ sendInvoice: true })}
                >
                  <MailIcon className="w-5 h-5"></MailIcon>
                  {this.props.invoice?.sent
                    ? "Send Again"
                    : `Send with ${connectionType === "qbo" ? "QuickBooks" : "Xero"}`}
                </button>
                {this.props.invoice.external_url && (
                  <button
                    className="flex items-center gap-2 py-1 pl-1 pr-2 text-xs transition-all border-2 rounded-md lg:pr-5 lg:pl-3 lg:py-2 text-platform border-platform hover:bg-gray-50"
                    onClick={(e) => this.copyToClipboard(this.props.invoice.external_url)}
                  >
                    <ClipboardCopyIcon className="w-5 h-5"></ClipboardCopyIcon>
                    Copy Link
                  </button>
                )}

                <button
                  className="flex items-center gap-2 py-1 pl-1 pr-2 text-xs transition-all border-2 rounded-md lg:pr-5 lg:pl-3 lg:py-2 text-platform border-platform hover:bg-gray-50"
                  onClick={(e) => this.markSent()}
                  disabled={this.props.invoice.sent}
                >
                  {this.props.invoice.sent ? (
                    <CheckCircleIconSolid className="w-5 h-5"></CheckCircleIconSolid>
                  ) : (
                    <CheckCircleIcon className="w-5 h-5"></CheckCircleIcon>
                  )}
                  Mark{this.props.invoice.sent && "ed"} as sent
                </button>
              </div>
            </div>
          )}
          <div
            className={classNames(
              isLocked && "locked",
              isCollapsible && this.state.isCollapsed && "hidden",
              "border relative border-t-0 border-gray-200 hover:border-gray-300"
            )}
          >
            {this.props.error && (
              <div className="flex items-center flex-1 py-1 pl-5 text-xs text-red-900 bg-red-200">
                Error Syncing: {this.props.error.message}{" "}
                {this.props.error.message === "Invoice not of valid status for modification" && (
                  <button
                    className="inline ml-1 underline"
                    onClick={(e) => this.props.refreshInvoice(this.props.invoice.external_id)}
                  >
                    try refreshing the invoice status
                  </button>
                )}
              </div>
            )}

            <div className="flex flex-row px-5 py-3 space-x-5 bg-gray-50">
              <div className="flex flex-col flex-1 w-full">
                <div className="max-w-max">
                  <label className="block w-full text-xs text-grey">Invoice Date</label>
                  <input
                    className="flex text-xl font-semibold border-b bg-inherit border-inherit focus:outline-none focus:border-platform"
                    title="Add Date"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    //defaultValue={this.props.invoice.date}
                    value={this.props.invoice.date}
                    onChange={(e) => {
                      this.props.updateInvoice(this.props.invoiceIndex, "date", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-row items-center gap-4 mt-2 max-w-min">
                  <label className="block text-xs text-grey">Due</label>

                  <div className="flex items-center gap-2 no-wrap">
                    {this.props.preferences?.due_date ||
                    (connectionType === "xero" &&
                      (this.props.deal.settings?.terms && !Object.keys(this.props.deal.settings.terms).length > 0)) ? (
                      <input
                        title="Add Date"
                        type="date"
                        className="flex text-sm border-b bg-inherit border-inherit focus:outline-none focus:border-platform"
                        placeholder="YYYY-MM-DD"
                        value={this.props.invoice.due_date}
                        onChange={(e) => {
                          this.props.updateInvoice(
                            this.props.invoiceIndex,
                            "due_date",
                            e.target.value
                          );
                        }}
                      />
                    ) : (
                      <>
                        {this.props.invoice.due_date && (
                          <>{new Date(this.props.invoice.due_date).toLocaleDateString()}</>
                        )}
                      </>
                    )}
                    {this.props.invoice.overdue && (
                      <div className="flex items-center text-sm text-red-600">
                        <ExclamationIcon className="w-5 mr-1"></ExclamationIcon>Overdue
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center gap-4 mt-2 flex-nowrap max-w-min">
                  <span className="flex-shrink block text-xs text-grey">Status</span>
                  <div className="text-sm whitespace-nowrap">
                    {connectionType !== "qbo" &&
                    this.props.preferences.hasOwnProperty("statuses") &&
                    (this.props.preferences.statuses.allowed.includes(this.props.invoice.status) ||
                      this.props.invoice.status === "") ? (
                      <>
                        <div className="relative inline-block w-32">
                          <select
                            onChange={(e) => {
                              this.props.updateInvoice(
                                this.props.invoiceIndex,
                                "status",
                                e.target.value
                              );
                            }}
                            value={this.props.invoice.status}
                            className="block w-full px-2 py-1 pr-8 text-sm leading-tight bg-white border border-gray-200 rounded-none appearance-none focus:border-platform focus:outline-none"
                          >
                            {this.props.preferences.statuses.allowed.map((status, i) => {
                              if (status === "APPROVED") {
                                return <></>; // Not available on non-repeating invoice
                              } else {
                                return (
                                  <option key={i} value={status}>
                                    {status === "DRAFT" ? "Draft" : ""}
                                    {status === "SUBMITTED" ? "To Approve" : ""}
                                    {status === "AUTHORISED" ? "Authorised" : ""}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                            <svg
                              className="w-4 h-4 fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* TO DO - use statusMapping here */}
                        {this.props.invoice.status === "AUTHORISED" ? <div>Authorised</div> : ""}
                        {this.props.invoice.status === "PAID" ? <div>Paid</div> : ""}
                        {this.props.invoice.status === "VOIDED" ? <div>Voided</div> : ""}
                        {this.props.invoice.status === "SENT" ? <div>Sent</div> : ""}
                        {this.props.invoice.status === "SUBMITTED" ? <div>To Approve</div> : ""}
                        {this.props.invoice.status === "DELETED" ? <div>Deleted</div> : ""}
                        {this.props.invoice.status === "DRAFT" ? <div>Draft</div> : ""}
                        {this.props.invoice.status === "" &&
                        this.props.invoice.external_id !== "" ? (
                          <div>Draft</div>
                        ) : (
                          ""
                        )}
                        {this.props.invoice.status === "" &&
                        this.props.invoice.external_id === "" ? (
                          <div>Ready to sync</div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap flex-1 h-full">{customFields}</div>
            </div>
            <div className="flex flex-col w-full px-4 pt-4 pb-2 bg-white">
              <Droppable droppableId={this.props.invoice.key}>
                {(provided, snapshot) => (
                  <div className="w-full" ref={provided.innerRef}>
                    <div className="relative">
                      <div className="flex flex-row border-b">
                        <div className="flex items-center justify-center w-4 bg-white cursor-grab">
                          <></>
                        </div>
                        {this.props.preferences.serviceDateEnabled && (
                          <span className="block w-32 text-xs text-grey">Service Date</span>
                        )}
                        {this.props.preferences.productsEnabled && (
                          <span className="block w-48 text-xs text-grey">Item</span>
                        )}
                        <span className="flex-1 block pl-1 mr-4 text-xs text-grey">
                          Description
                        </span>
                        {!this.props.invoice.customTaxes && !isQboUs && (
                          <span className="block w-32 ml-1 text-xs text-left text-grey">
                            Tax
                          </span>
                        )}
                        <span className="block w-12 pl-1 text-xs text-center text-grey">Qty</span>
                        <span className="block w-32 pl-1 text-xs text-right text-grey">Price</span>
                        {connectionType === "xero" && (
                          <span className="block w-16 ml-1 text-xs text-right text-grey">
                            Discount
                          </span>
                        )}
                        <span className="block w-32 pl-1 text-xs text-right text-grey ">
                          Subtotal
                        </span>
                        {isQboUs && (
                          <span className="block w-12 pl-1 text-xs text-center text-grey">Tax</span>
                        )}
                        <div className="w-12"></div>
                      </div>

                      {lineItemsList}

                      {provided.placeholder}

                      <button
                        className="absolute flex justify-center pt-1 text-sm underline text-platform"
                        onClick={this.addLineItem}
                      >
                        <PlusIcon className="inline w-4 mr-1" />
                        Add Line Item
                      </button>
                    </div>
                  </div>
                )}
              </Droppable>

              {this.props.preferences.discountEnabled ||
              this.props.deal.settings.discountEnabled ? (
                <div className="flex flex-row justify-end gap-2 my-4">
                  {this.props.invoice.hasOwnProperty("discount") &&
                  this.props.invoice.discount.enabled ? (
                    <>
                      <div className="flex flex-col">
                        <label className="text-xs text-grey">Discount</label>
                        <input
                          type="text"
                          className="mr-2 border-b border-inherit focus:outline-none focus:border-platform"
                          placeholder="Value"
                          value={this.props.invoice.discount.value}
                          onChange={(e) => {
                            this.props.updateInvoice(this.props.invoiceIndex, "discount", {
                              value: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="flex flex-col text-right">
                        <span className="text-xs text-grey">Discount Total</span>

                        <NumberDisplay
                          className="text-md"
                          value={
                            this.props.invoice.discount.hasOwnProperty("total")
                              ? this.props.invoice.discount.total
                              : "pending"
                          }
                          currency={this.props.invoice.currency}
                        />
                      </div>
                    </>
                  ) : (
                    <button
                      className="flex justify-center py-1 pr-1 text-sm underline text-platform"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.updateInvoice(this.props.invoiceIndex, "discount", {
                          total: 0,
                          enabled: true,
                        });
                      }}
                    >
                      <PlusIcon className="inline w-4 ml-1 mr-1" />
                      Add Discount
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="flex flex-row w-full">
                <div className="flex flex-col justify-end flex-shrink">
                  {!isQboUs || (isQboUs && this.hasEditableTax()) ? (
                    <>
                      {this.props.invoice.customTaxes ? (
                        <button
                          className="flex justify-center text-xs underline text-platform"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.setTaxAndAccountsToDealSettings(this.props.invoiceIndex);
                            this.props.updateInvoice(this.props.invoiceIndex, "customTaxes", false);
                          }}
                        >
                          {connectionType === "qbo" ? "Use Default Taxes" : "Use Default Accounts and Taxes"}
                        </button>
                      ) : (
                        <button
                          className="flex justify-center text-xs underline text-platform"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.resetAccounts(this.props.invoiceIndex);
                            this.props.updateInvoice(this.props.invoiceIndex, "customTaxes", true);
                          }}
                        >
                          {connectionType === "qbo" ? "Customise Taxes" : "Customise Accounts and Taxes"}
                        </button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex flex-col items-end justify-end flex-grow gap-1 text-right">
                  <label className="flex items-center gap-5 mt-2 invoice_total">
                    <span className="text-xs text-grey">Subtotal</span>
                    <NumberDisplay
                      className="text-3xl font-semibold"
                      value={this.props.invoice.total}
                      currency={this.props.invoice.currency}
                    />
                  </label>

                  {isQboUs && this.hasEditableTax() && this.props.invoice.customTaxes && <TaxSelect
                      value={taxList.find((x) => x.value === this.props.invoice.taxType)}
                      list={taxList}
                      update={this.updateTax} // = updateTax function - diff to settings as this only updates the invoice.
                      disabled={false}
                      country={this.props.connectionData?.country}
                      horizontal={true}
                      platformStyles={this.props.platformStyles}
                    />
                  }
                  
                  {this.totalIncTaxComponent()}

                  {this.props.invoice.creditIncTax > 0 && (
                    <div className="flex items-center gap-5 text-right">
                      <label className="text-xs text-grey">Credit</label>
                      <NumberDisplay
                        className="w-24"
                        value={-this.props.invoice.creditIncTax}
                        currency={this.props.invoice.currency}
                      />
                    </div>
                  )}
                  {this.props.invoice?.amount_paid > 0 && (
                    <div className="flex items-center gap-5 text-right border-b min-w-fit">
                      <label className="text-xs text-grey">Paid</label>
                      <NumberDisplay
                        className="min-w-24 min-w-fit"
                        value={-this.props.invoice.amount_paid}
                        currency={this.props.invoice.currency}
                      />
                    </div>
                  )}
                  {(this.props.invoice?.amount_paid > 0 || this.props.invoice.creditIncTax) &&
                  Number(this.props.invoice?.amount_due) !== this.props.invoice.totalIncTax ? (
                    <div className="flex items-center gap-5 text-right">
                      <label className="text-xs text-grey">Due</label>
                      <NumberDisplay
                        className="w-24"
                        value={this.props.invoice.amount_due}
                        currency={this.props.invoice.currency}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Invoice;
