import React from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";

import asyncDebounce from "../utils/debounceAsync";

const api = process.env.REACT_APP_API_ROOT;

class InvoicesField extends React.Component {
  constructor(props) {
    super(props);
  }

  searchInvoices = asyncDebounce(async (searchTerm) => {
    const accessToken = new URLSearchParams(document.location.search).get("access_token");
    const results = await fetch(api + "/invoices/search/" + searchTerm, {
      headers: {
        "Content-Type": "application/json",
        accesstoken: accessToken,
      },
    });

    const json = await results.json();
    return json;
  });

  loadOptions = (query) =>
    new Promise(async (resolve) => {
      if (query.length > 2) {
        let results = await this.searchInvoices(query);
        resolve(results.invoices);
      }
    });


  render() {
    const InvoiceControl = ({ children, ...props }) => {
      const selectedOption = props.getValue();

      return (
        <components.Control {...props}>
          <div className="flex flex-wrap items-center w-full">
            {selectedOption[0] ? (
              <div className="flex-1 min-w-0 p-2">
                <span className="block overflow-hidden text-xs text-ellipsis whitespace-nowrap">
                  {selectedOption[0]?.invoiceNumber}
                </span>
              </div>
            ) : (
              <div className="flex-1 min-w-0 p-1">
                <span className="block overflow-hidden text-xs text-ellipsis whitespace-nowrap text-grey">
                  {children[0]}
                </span>
              </div>
            )}
            <div className="shrink-0">{children[1]}</div>
          </div>
        </components.Control>
      );
    };

    const InvoiceOption = (props) => {
      const selectedOption = props.data;

      return (
        <components.Option {...props}>
          <div className="text-xs">
            <div className={props.isSelected ? "text-white " : ""}>{selectedOption?.invoiceNumber}</div>
          </div>
        </components.Option>
      );
    };

    return (
      <div className="flex-1 w-full">
        <AsyncSelect
          className="text-sm w-72"
          loadingMessage={(e) =>
            e.inputValue.length < 3 ? "Type at least 3 characters" : "Searching"
          }
          noOptionsMessage={(e) =>
            e.inputValue.length < 3 ? "Type at least 3 characters" : "No invoices match"
          }
          value={this.props.invoice}
          placeholder={this.props.connectionType === "xero" ? "Enter invoice number or reference" : "Enter invoice number"}
          components={{ Option: InvoiceOption, Control: InvoiceControl }}
          isClearable
          openMenuOnFocus="true"
          loadOptions={this.loadOptions}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: this.props.platformStyles['--platformlight'],
              primary50: this.props.platformStyles['--platformlight'],
              primary: this.props.platformStyles['--platform'],
            },
            spacing: {
              ...theme.spacing,
              baseUnit: 3,
            },
          })}
          onChange={(selectedOption) => {
            this.props.selectInvoice(selectedOption);
          }}
        />
      </div>
    );
  }
}

export default InvoicesField;
