/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InvoiceActions({
  connectionType,
  cloneAction,
  deleteAction,
  deleteEnabled,
  viewLink,
  pdfLink,
  copyAction,
  externalUrl,
  changeAction,
  changeEnabled,
  invoiceIndex,
  splits,
  splitAction,
  cancelEnabled,
  cancelAction,
  replaceEnabled,
  replaceAction,
  refreshEnabled,
  refreshAction
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          onClick={(e) => e.stopPropagation()}
          className="flex items-center bg-white rounded-full text-platform focus:outline-none ring-2 ring-platform ring-offset-2"
        >
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {viewLink && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={viewLink}
                    target="_blank"
                    className={classNames(
                      active ? "bg-platformlight text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm  w-full text-left"
                    )}
                  >
                    View in {connectionType === "xero" ? "Xero" : "QuickBooks"}
                  </a>
                )}
              </Menu.Item>
            )}
            {changeEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={changeAction}
                    className={classNames(
                      active ? "bg-platformlight text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left"
                    )}
                  >
                    Edit Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {replaceEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={replaceAction}
                    className={classNames(
                      active ? "bg-platformlight text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left"
                    )}
                  >
                    Edit Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {refreshEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={refreshAction}
                    className={classNames(
                      active ? "bg-platformlight text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left"
                    )}
                  >
                    Refresh Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {externalUrl && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={copyAction}
                    className={classNames(
                      active ? "bg-platformlight text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left"
                    )}
                  >
                    Copy Invoice Link
                  </button>
                )}
              </Menu.Item>
            )}
            {pdfLink && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={pdfLink}
                    target="_blank"
                    className={classNames(
                      active ? "bg-platformlight text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left"
                    )}
                  >
                    Get PDF Invoice
                  </a>
                )}
              </Menu.Item>
            )}
            {externalUrl && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={externalUrl}
                    target="_blank"
                    className={classNames(
                      active ? "bg-platformlight text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm  w-full text-left"
                    )}
                  >
                    Preview Invoice
                  </a>
                )}
              </Menu.Item>
            )}

            {changeEnabled || (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={cloneAction}
                    className={classNames(
                      active ? "bg-platformlight text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm  w-full text-left"
                    )}
                  >
                    Duplicate Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {splits && splits.length > 0 && (
              <div className="block py-2 border-y">
                <div className="block w-full px-4 py-2 text-sm font-semibold text-left text-gray-700">
                  Payment Schedules
                </div>

                {splits.map((split) => (
                  <Menu.Item key={"split_" + split.id}>
                    {({ active }) => (
                      <button
                        onClick={(e) => splitAction(invoiceIndex, split)}
                        target="_blank"
                        className={classNames(
                          active ? "bg-platformlight text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm w-full text-left"
                        )}
                      >
                        <div className="pl-2">{split.label}</div>
                        <div className="flex flex-row gap-1 pl-2 text-xs text-gray-400">
                          {split.percentages
                            .split("%")
                            .filter((i) => parseFloat(i))
                            .map((value) => (
                              <div className="text-gray-400">{value}%</div>
                            ))}
                        </div>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            )}
            {deleteEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={deleteAction}
                    className={classNames(
                      active ? "bg-platformlight text-red-600" : "text-red-600",
                      "block px-4 py-2 text-sm  w-full text-left"
                    )}
                  >
                    Delete Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {cancelEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={cancelAction}
                    className={classNames(
                      active ? "bg-platformlight text-red-600" : "text-red-600",
                      "block px-4 py-2 text-sm  w-full text-left"
                    )}
                  >
                    Cancel Invoice
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
