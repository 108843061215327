import { ExclamationCircleIcon } from "@heroicons/react/outline";

export default function ErrorState({
  h1 = "Error",
  message = "There was a problem processing your request",
  continueUrl = "/",
  fullPage = true,
}) {
  return (
    <div className="flex flex-col justify-center w-screen h-screen">
      <div className="flex items-center h-48 justify-contents">
        <span className="flex flex-col items-center flex-auto">
          <ExclamationCircleIcon
            className="flex-auto w-10 h-10 m-3 text-red-300"
            aria-hidden="true"
          />
          <span className="flex-auto text-base text-gray-800 ">{h1}</span>
          <span
            className="flex-auto text-sm text-gray-400 "
            dangerouslySetInnerHTML={{ __html: message }}
          ></span>
          <span className="mt-2 text-sm text-gray-600">
            Continuing to have problems?
            <a className="ml-2 text-primary" href={process.env.REACT_APP_SUPPORT_LINK} target="_blank">
              Reach out to the support team
            </a>
          </span>
        </span>
      </div>
    </div>
  );
}
