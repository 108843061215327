import React from "react";
import { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

let api = process.env.REACT_APP_API_ROOT;

const accessToken = new URLSearchParams(document.location.search).get("access_token");

class EditContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      emails: [],
    };
  }

  componentDidUpdate = async(prevProps) => {
    if (!prevProps.open && this.props.open) {
        this.setState({ loading: true });
        await this.getContact();
    }
  }

  async getContact() {
    console.log("getContact");
    this.setState({ loading: true });
    fetch(api + "/contacts/getContact/" + this.props.contactId, {
      headers: { "Content-Type": "application/json", accesstoken: accessToken },
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false });
        if (res.error && res.reason) {
          this.props.setError(res.reason);
        } else if (res.error) {
          throw res.error;
        } else {
          this.setState(res.contact[0]);
          console.log(res);
          this.setState({ loading: false });

          // this.props.addNewContact(res.contact);
          // this.props.setSuccess("Contact Added");
        }
      })
      .catch((error) => {
        this.props.setError("Error fetching contact");
        console.error(error);
      });
  }

  async submit() {
    console.log("update contact");
    this.setState({ loading: true });
    fetch(api + "/contacts/update/" + this.props.contactId, {
      headers: { "Content-Type": "application/json", accesstoken: accessToken },
      method: "post",
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false });
        if (res.error && res.reason) {
          this.props.setError(res.reason);
        } else if (res.error) {
          throw res.error;
        } else {
          this.props.replaceContactData();
          this.props.setSuccess(
            "Contact info has been updated inside Xero",
            false,
            "Contact Updated"
          );
        }
      })
      .catch((error) => {
        this.props.setError("Error: " + error);
        console.error(error);
      });
  }

  render() {
    return (
      <>
        <Transition.Root show={this.props.open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-hidden"
            onClose={(e) => this.props.setClose()}
            style={this.props.platformStyles}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-0 transition-opacity bg-white bg-opacity-75"> </div>
              </Transition.Child>
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                      <div className="flex flex-col flex-1 min-h-0 py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Edit Contact
                            </Dialog.Title>
                            <div className="flex items-center ml-3 h-7">
                              <button
                                type="button"
                                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none"
                                onClick={() => this.props.setClose(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="w-6 h-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative flex-1 px-4 mt-6 sm:px-6">
                          <div className="form">
                            <div className="flex flex-col gap-3">
                              <div>
                                <label>
                                  Company Name <span className="req">(required)</span>
                                </label>
                                <input
                                  type="text"
                                  value={this.state.name}
                                  onChange={(event) => this.setState({ name: event.target.value })}
                                />
                              </div>
                              <div className="basis-1/2">
                                <label>Contact First Name</label>
                                <input
                                  type="text"
                                  value={this.state.firstname}
                                  onChange={(event) =>
                                    this.setState({
                                      firstname: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <label>Contact Last Name</label>
                                <input
                                  type="text"
                                  value={this.state.lastname}
                                  onChange={(event) =>
                                    this.setState({
                                      lastname: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <label>Email Address</label>
                                <div className="flex flex-col gap-2">
                                  {this.state?.emails &&
                                    this.state?.emails.length > 0 &&
                                    this.state.emails.map((email, index) => (
                                      <div key={index} className="relative group">
                                        <input
                                          type="text"
                                          value={email}
                                          onChange={(event) => {
                                            this.setState({
                                              emails: this.state.emails.map((x, i) =>
                                                i === index ? event.target.value : x
                                              ),
                                            });
                                          }}
                                        />
                                        {this.state.emails.length > 1 && (
                                          <button
                                            type="button"
                                            className="absolute top-0 right-0 h-full text-xs text-red-700 transition-opacity duration-500 opacity-0 group-hover:opacity-100"
                                            onClick={() => {
                                              this.setState({
                                                emails: this.state.emails.filter(
                                                  (x, i) => i !== index
                                                ),
                                              });
                                            }}
                                          >
                                            Remove
                                          </button>
                                        )}
                                      </div>
                                    ))}
                                </div>
                                {this.state.emails.length < 6 &&
                                  this.props.connectionData.connectionType === "xero" && (
                                    <button
                                      type="button"
                                      className="text-xs underline text-platform"
                                      onClick={() => {
                                        this.state.emails.length < 6 &&
                                          this.setState({ emails: [...this.state.emails, ""] });
                                      }}
                                    >
                                      Add Additional Email
                                    </button>
                                  )}
                              </div>
                              <div>
                                <label>Phone Number</label>
                                <input
                                  type="text"
                                  value={this.state.phone}
                                  onChange={(event) => this.setState({ phone: event.target.value })}
                                />
                              </div>
                              {!this.props.b2c && (
                                <div>
                                  <label>Tax ID Number (e.g. VAT or ABN)</label>
                                  <input
                                    type="text"
                                    value={this.state.taxnumber}
                                    onChange={(event) =>
                                      this.setState({
                                        taxnumber: event.target.value,
                                      })
                                    }
                                  />
                                </div>
                              )}
                              <div>
                                <label>Address</label>
                                <input
                                  type="text"
                                  value={this.state.addressline1}
                                  onChange={(event) =>
                                    this.setState({
                                      addressline1: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <label>Address Line 2</label>
                                <input
                                  type="text"
                                  value={this.state.addressline2}
                                  onChange={(event) =>
                                    this.setState({
                                      addressline2: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <label>City</label>
                                <input
                                  type="text"
                                  value={this.state.addresscity}
                                  onChange={(event) =>
                                    this.setState({
                                      addresscity: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <label>State/Region</label>
                                <input
                                  type="text"
                                  value={this.state.addressregion}
                                  onChange={(event) =>
                                    this.setState({
                                      addressregion: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <label>ZIP / Postal Code</label>
                                <input
                                  type="text"
                                  value={this.state.addresspostcode}
                                  onChange={(event) =>
                                    this.setState({
                                      addresspostcode: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <label>Country</label>
                                <input
                                  type="text"
                                  value={this.state.addresscountry}
                                  onChange={(event) =>
                                    this.setState({
                                      addresscountry: event.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end flex-shrink-0 px-4 py-4">
                        <button
                          type="button"
                          className="w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                          onClick={() => this.props.setClose()}
                        >
                          Cancel
                        </button>
                        {this.state.loading ? (
                          <button
                            type="submit"
                            className="inline-flex justify-center w-full px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-platform"
                          >
                            Saving...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            onClick={(e) => this.submit()}
                            className="inline-flex justify-center w-full px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-platform"
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default EditContact;
